<!-- 农业生产 -->
<template>
	<div>
		<div class="page_temp page_temp_left">
			<div class="contentBox">
				<item-title name="土地用途" english="land use" />
				<lineBorderBox type='bottom' />
				<div class="search ">
					<!-- <i class="el-icon-search size17" v-if="!searchState"></i> -->
					<input type="text" class=" searchInput" v-model="searchText" placeholder="请输入姓名或身份证号">
					<i class="el-icon-close " v-if="searchState"
						@click="searchText = '', searchState = false,landSearchData=[]"></i>
					<div class="search-btn " @click="searchLand">搜索</div>
					<div class="searchListBox" v-if="searchState&&landSearchData">
						<ul class="searchList" v-if="landSearchList && searchText != ''">
							<li @click="requestDkBySmid(item.id),searchText = item.ownerName"
								v-for="(item, index) in landSearchData" :key="index">
								<span>{{ item.ownerName }}</span>
								<span>地块-{{ item.id }}</span>
							</li>
						</ul>
					</div>
				</div>
				<div class="pie-box" style="padding-bottom: 0.2rem;">
					<div class="pie-chart">
						<div id="pie-chart"></div>
						<span class="center icon iconfont">&#xe600;</span>
					</div>
					<div class="pie-hint">
						<div><span style="background-color: rgb(127, 194, 81);"></span>农用地<p>{{emphasis_echarts[0]}}%</p></div>
						<div><span style="background-color: rgb(49, 144, 210)"></span>建设用地<p>{{emphasis_echarts[1]}}%</p></div>
						<div><span style="background-color: rgb(227, 92, 122);"></span>未利用地<p>{{emphasis_echarts[2]}}%</p></div>
					</div>
				</div>
			</div>
			<div style="margin-top: 0.2rem;">
				<item-title name="产业布局" english="Industrial Distribution" />
				<div style="display: flex;justify-content: space-between;align-items: center;">
					<div class="info_title">
						<div class="info_dot"><span></span></div>
						<span style="font-size: 0.14rem;color: #00FFFF;margin-left: 0.1rem;">产量统计<cite>(2022-12)</cite></span>
					</div>
				</div>
				<div class="contentBox industry " style="padding: 0 0.08rem 0.2rem;">
					<lineBorderBox type='bottom' />
					<span style="display: inline-block;width: 100%;text-align: left;font-size: .12rem;">单位（吨）</span>
					<div id="industryCharts"></div>
					<div style="display: flex;justify-content: space-between;align-items: center;">
						<div class="info_title">
							<div class="info_dot"><span></span></div>
							<span style="font-size: 0.14rem;color: #00FFFF;margin-left: 0.1rem;">产业信息<cite>(2022-12)</cite></span>
						</div>
					</div>
					<div class="tab-list">
						<div v-for="(item, index) in industryTabData" :key="index"
							:class="tabTitleCur == index ? 'tab-title-cur' : ''" @click="tabTitleCur = index"><span>{{
        item.name
}}</span></div>
					</div>
					<table border="1">
						<tr>
							<th v-for="(item, index) in industryTabData[tabTitleCur].th" :key="index">{{ item
}}
							</th>
						</tr>
						<tr v-for="(item, index) in industryTabData[tabTitleCur].td.slice(0, 10)" :key="index">
							<td>{{ item.cropName }}</td>
							<td>{{ item.areaValue }}</td>
							<td>{{ item.productionValue }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="page_temp page_temp_right">

			<div class="contentBox">
				<item-title name="乡村经营主体" english="Rural business entity" />
				<div
					style="display:flex;width:98%;justify-content: space-between;margin-top: .3rem;padding-bottom: 0.2rem;">
					<lineBorderBox type='bottom' />
					<div class="content_img_item" @click="onCheckType2(0)">
						<img :src="require('../assets/img/farming/e1.png')">
						<p>合作社</p>
						<div><span>{{entityData[0]?entityData[0].length:'--'}}</span>个</div>
					</div>
					<div class="content_img_item" @click="onCheckType2(1)">
						<img :src="require(`@/assets/img/farming/e2.png`)">
						<p>企业</p>
						<div><span>{{entityData[1]?entityData[1].length:'--'}}</span>个</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 0.2rem;">
				<item-title name="农业设备" english="agricultural equipment" />
				<div class="contentBox equipment "
					style="padding-top: .1rem; display: flex;flex-direction: column; justify-content: space-between;width: 100%;flex-wrap: wrap;padding-bottom: 0.2rem;">
					<lineBorderBox type='bottom' />
					<div class="content_img_item_list" @click="onCheckType2(2)">
						<img :src="require('../assets/img/farming/f1.png')">
						<p>农机设备</p>
						<div>
							<span>设备数量（个）</span>
							<span>{{ agriculturalEquipmentData.length || '--' }}</span>
						</div>
					</div>
					<div class="content_img_item_list" @click="onCheckType2(3)">
						<img :src="require(`@/assets/img/farming/f2.png`)">
						<p>无人机</p>
						<div>
							<span>设备数量（个）</span>
							<span>{{ droneData.length || '--' }}</span>
						</div>
					</div>
					<div class="content_img_item_list" @click="onCheckType2(4)">
						<img :src="require('../assets/img/farming/f3.png')">
						<p>物联网设备</p>
						<div>
							<span>设备数量（个）</span>
							<span>{{ iotDeviceData.length || '--' }}</span>
						</div>
					</div>
					<div class="content_img_item_list" @click="onCheckType2(5)">
						<img :src="require('../assets/img/farming/f5.png')">
						<p>农产品快检设备</p>
						<div>
							<span>设备数量（个）</span>
							<span>{{ v_device_data.length || '--' }}</span>
						</div>
					</div>
					<!-- <div class="content_img_item_list" @click="onCheckType2(4)">
						<img :src="require(`@/assets/img/farming/f4.png`)">
						<p>其他设备</p>
						<div>
							<span>设备数量（个）</span>
							<span>{{ iotDeviceData.length || '--' }}</span>
						</div>
					</div> -->
				</div>

			</div>
		</div>
		<!-- 弹窗 -->
		<div class="landWinPop" v-if="landWinPop">
			<!-- <p>{{landWinPop}}</p> -->
			<div class="title">
				<span>承包地</span>
				<i class="el-icon-circle-close" @click="closeLandWinPop"></i>
			</div>
			<el-row class="row-1">
				<el-col :span="6">
					<img src="../assets/new_img/z1.png" alt="">
					<div>
						<span>确权时间：</span>
						<p>2017-06-15</p>
					</div>
				</el-col>
				<el-col :span="6">
					<img src="../assets/new_img/z2.png" alt="">
					<div>
						<span>承包期限：</span>
						<p>10年</p>
					</div>
				</el-col>
				<el-col :span="6">
					<img src="../assets/new_img/z3.png" alt="">
					<div>
						<span>地块面积：</span>
						<p>{{ v_dk_info.mj || '--' }}亩</p>
					</div>
				</el-col>
				<el-col :span="6">
					<img src="../assets/new_img/z4.png" alt="">
					<div>
						<span>耕地类型：</span>
						<p>{{ v_dk_info.dklx || '--' }}</p>
					</div>
				</el-col>
			</el-row>
			<ul class="row-2">
				<li class="flex_c">
					<img src="../assets/img/farming/1.png" alt="" height="26" width="26">
					<div>
						<p>户主姓名：</p>
						<p>{{ v_hu_info.xm || '--' }}</p>
					</div>
					<!-- <span>户主姓名：</span><span>{{ v_hu_info.xm || '--' }}</span> -->
				</li>
				<li class="flex_c">
					<img src="../assets/img/farming/4.png" alt="" height="26" width="26">
					<div>
						<p>出生日期：</p>
						<p>{{ v_hu_info.csrq || '--' }}</p>
					</div>
				</li>
				<li class="flex_c">
					<img src="../assets/img/farming/2.png" alt="" height="26" width="26">
					<div>
						<p>家庭人数：</p>
						<p>{{ v_hu_info.rks || '--' }}</p>
					</div>
				</li>
				<li class="flex_c">
					<img src="../assets/img/farming/5.png" alt="" height="26" width="26">
					<div>
						<p>联系电话：</p>
						<p>{{ v_hu_info.lxdh || '--' }}</p>
					</div>
				</li>
				<!-- <li><span>出生日期：</span><span>{{ v_hu_info.csrq || '--' }}</span></li>
				<li><span>家庭人数：</span><span>{{ v_hu_info.rks || '--' }}</span></li>
				<li><span>联系电话：</span><span>{{ v_hu_info.lxdh || '--' }}</span></li> -->
				<li class="flex_c" style="width: 100%;">
					<img src="../assets/img/farming/3.png" alt="" height="26" width="26">
					<div>
						<p>家庭地址：</p>
						<p>{{ v_hu_info.dz || '--' }}</p>
					</div>
					<!-- <span>家庭地址：</span><span>{{ v_hu_info.dz || '--' }}</span> -->
				</li>
			</ul>
			<div class="row-3">
				<span v-for="(item, index) in landTabData" :key="index"
					:class="landTitleCur == index ? 'cur_pop_list' : ''" @click="landTitleCur = index">{{ item.name}}
				</span>
			</div>
			<div class="row-4">
				<span v-for="(item, index) in landTabData[landTitleCur].th" :key="index">{{item}}</span>
			</div>
			<div style="height: 1.6rem;overflow: auto;" class="scrollbar">
				<div class="row-5" v-for="(item, index) in landTabData[landTitleCur].td.slice(0, 12)" :key="index">
					<span>{{ item.name }}</span>
					<span>{{ item.m1 }}</span>
					<span v-if="item.m2 != undefined">{{ item.m2 }}</span>
					<span v-if="item.m3 != undefined">{{ item.m3 }}</span>
					<span v-if="item.m4 != undefined">{{ item.m4 }}</span>
				</div>
			</div>
		</div>
		<div v-for="(item, index) in dotData" :key="index" v-show="dotPopActive != -1" @click="showXcjyztInfo(item)" >
			<dot-pop :name="item.name" :x="item.geometry.pointArr[0]" :y="item.geometry.pointArr[1]" :z="7"
				 :color="dotColor" :type="'flag'" :isShow="item.isShow" :intro="item.intro"  ref="ref_pop_info">
				<!-- <img style="width: .30rem;" :src="dotIcon"> -->
				<template #head>
					<div style="height: 40px;line-height: 40px;border-bottom: 1px solid rgba(225, 225, 225, 0.6);font-size:12px;">
						<el-col :span="24" style="font-size:16px;">{{item.name}}</el-col>
					</div>
				</template>
				<template #body>
					<el-col :span="24"><div class="cls-pane-content scrollbar">{{item.intro}}</div></el-col>
				</template>
			</dot-pop>
		</div>
	</div>

</template>

<script>
	import lineBox from '../components/line-border-box.vue'
	import itemTitle from '../components/item-title.vue'
	import * as echarts from 'echarts';
	import {
		industryOptions,
		landTabData,
		industryTabData,
		wisdowData
	} from '../components/farming/data.js'
	import pieChart from '../components/farming/pie-chart.vue'
	import {
		getLandList,
		getIndustrialList,
		getEntity,
		getEquipment,
		getSearchLand,
		getLand,
		getDrone,
		getIotDevice
	} from '@/api/farming.js'
	import {
		addPolygonFunction,
		getLonLatArray,
		cleanMarkerListByType,
		addPoint,
		getRegionFromPoints,
		getMakerById
	} from '@/util/drawShape.js'
	import {
		getDiShiZhouCodeByCunCode,
		getCsrqFromSfzh,
		getEnvelope
	} from '../util/common.js';
	import http from '../util/http.js';
	import config from '@/config.js'
	import dotPop from "@/components/dot-pop.vue";
	import lineBorderBox from '../components/line-border-box.vue'

	var g_tdly_list = [{
			type: 'tdyt0',
			name: '农用地',
			area: 0,
			unit: '平方米',
			isShow: true,
			list: []
		},
		{
			type: 'tdyt1',
			name: '建设用地',
			area: 0,
			unit: '平方米',
			isShow: true,
			list: []
		},
		{
			type: 'tdyt2',
			name: '未利用地',
			area: 0,
			unit: '平方米',
			isShow: true,
			list: []
		}
	];
	var g_handler = null;
	var g_dk_list = [];
	var g_dk_entity = null;

	var g_dycy_list = [];

	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			lineBox,
			itemTitle,
			pieChart,
			dotPop,
			lineBorderBox
		},
		data() {
			return {
				// 产业布局表格数据
				industryTabData: [],
				// 产业布局索引
				tabTitleCur: 0,
				landTitleCur:0,
				// 搜索的文字
				searchText: '',
				// 搜索状态
				searchState: false,
				// 智慧农业板块
				wisdoeActive: 1,
				// 智慧农业板块数据
				wisdowData: [],
				// 智慧农业板块图片动效
				translateX: 'translateX(0)',
				imgCurIndex: 0,
				// 土地用途数据
				landOption: {},
				// 乡村经营主体数据
				entityData: [],
				// 农业设备数据
				equipmentData: [],
				// 左侧菜单栏透明度
				page_temp_opcity: 1,
				// 左侧地块查询弹窗状态
				landWinPop: false,
				// 地块查询表格数据
				landTabData: [],
				// 地块搜索列表状态
				landSearchList: false,
				// 地块查询列表数据
				landSearchData: [],
				transfromLeft: '',
				transfromRight: '',

				v_hu_info: {
					hid: '',
					xm: '',
					csrq: '',
					lxdh: '',
					dz: ''
				},
				v_dk_info: {
					mc: '',
					lx: '',
					mj: '',
				},
				// 合作社定位
				v_if_show_cooperation: false,
				// 企业定位图标
				v_if_show_enterprise: false,
				// 农机设备数据
				agriculturalEquipmentData: [],
				// 农机设备定位状态
				v_if_show_agricultural: false,
				// 无人机
				droneData: [],
				v_if_drone: false,
				// 物联网设备
				iotDeviceData: [],
				v_device_data: [],
				v_if_iotDevice: false,
				img_agricultural: require('@/assets/new_img/n1.png'),
				img_droneData: require('@/assets/new_img/n2.png'),
				img_iotDeviceData: require('@/assets/new_img/n3.png'),
				dotPopActive: -1,
				dotIcon: '',
				dotData: {},
				pieData: [{
					value: '26',
					name: '农用地'
				}, {
					value: '36',
					name: '建设用地'
				}, {
					value: '18',
					name: '未利用地'
				}],
				g_xqid: localStorage.getItem('tenantCode'), //config.TENANT_CODE; //'420626107204';
				g_dsz_id: getDiShiZhouCodeByCunCode(localStorage.getItem('tenantCode')),
				colorList: [
					'rgb(127, 194, 81)', 'rgb(49, 144, 210)', 'rgb(227, 92, 122)'
				],
				dotColor: '',
				emphasis_echarts:[],
				isShow: true
			}

		},
		computed: {
			...mapState(['menu_active'])
		},
		created() {
			this.industryTabData = industryTabData
			this.industryTabData.forEach(item => {
				item.td = []
			})
			this.wisdowData = wisdowData
			this.landTabData = landTabData

			let index = 0
			setInterval(() => {
				index == 2 ? index = 0 : index++
				this.animationImg(index)
			}, 2000)

			let that = this
			document.onkeydown = function(e) {
				var ev = document.all ? window.event : e;
				if (ev.keyCode == 13 && that.searchText != '' && that.menu_active == 1) {
					that.searchLand()
				}
			}
		},
		mounted() {
			let pMountedTimer = window.setInterval(() => {
				if (window.parentMounted) {
					window.clearInterval(pMountedTimer)
					this.init();
				}
			}, 500)
		},
		watch: {
			landWinPop:function(newValue, oldValue) {
				console.log('new---',newValue)
				// this.landWinPop = newValue
			},
			menu_active: function(newValue, oldValue) {
				console.log('menu_active', newValue, oldValue);
				this.landWinPop = false
				this.page_temp_opcity = 1
				this.searchText = ''
				this.cleanAllPolygon()
				if (newValue == 1) {
					this.initLandData()
				}

				if (newValue != 1) {
					cleanMarkerListByType('tdyt0');
					cleanMarkerListByType('tdyt1');
					cleanMarkerListByType('tdyt2');
					cleanMarkerListByType('tdlydk');
					cleanMarkerListByType('tdlydkpoint');
				} else { //显示所有的土地利用面
					this.showAllTdlyPolygon();
				}
				if (newValue != 2) {
					cleanMarkerListByType('dycy');
				}
				if (newValue != 3) {
					// cleanMarkerListByType('cooperation');
					// cleanMarkerListByType('enterprise');
					this.dotPopActive = -1;
				}
				if (newValue != 4) {
					// cleanMarkerListByType('agricultural');
					// cleanMarkerListByType('droneData');
					// cleanMarkerListByType('iotDeviceData');
					this.dotPopActive = -1;
				}
			},
			searchText: function(newVal, oldVal) {
				this.landSearchData = []
				this.searchState = false
			}
		},
		methods: {
			init() {
				this.g_xqid = localStorage.getItem('tenantCode');
				this.initIndustryData()
				this.initLandData()
				this.getEntity()
				this.getEquipment()
				this.initLandMap()
				this.requestTdyt();
				g_handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
				g_handler.setInputAction(this.handlerAction, Cesium.ScreenSpaceEventType.LEFT_CLICK);

				this.$parent.show2DMap();
				// this.$nextTick(function() {
				// 	var myEvent = new Event("charts");
				// 	window.dispatchEvent(myEvent);
				// });
			},
			clearPopGhbq(item) {
				// item.hideInfo();
		},
			//显示所有的土地利用面
			showAllTdlyPolygon() {
				for (var i = 0; i < 3; i++) {
					var _config = {
						polygonFillColor: this.colorList[i],
						alpha: 0.6,
						classificationType: 'S3M_TILE'
					};
					for (var _item of g_tdly_list[i].list) {
						addPolygonFunction(g_tdly_list[i].type, _item.id, '', _item.shape, null, _config, _item.holes);
					}
					g_tdly_list[i].isShow = true;
				}
			},

			// 初始化土地用途数据
			initLandData: function() {
				let that = this
				getLandList().then(res => {
					if(res.rows.length == 0){
						return;
					}
					this.landOption = res.rows[0]
					this.pieData[0].value = res.rows[0].forestlandArea
					this.pieData[1].value = res.rows[0].plowlandArea
					this.pieData[2].value = res.rows[0].parkArea
					echarts.init(document.getElementById('pie-chart')).dispose();
					let charts = echarts.init(document.getElementById('pie-chart'));
					let option = {
						tooltip: {
							show: true,
							position: function (point, params, dom, rect, size) { // 提示框位置
        						let x = 0;
        						let y = 0;
        						//固定在中间
        						// if (point[0] + size.contentSize[0] > size.viewSize[0]) {
        						//    x = point[0] + size.contentSize[0]
        						// } else {
        						   x = point[0]
        						// }
        						if (point[1] > size.contentSize[1]) {
        						   y = point[1] - size.contentSize[1]
        						} else if (point[1] + size.contentSize[1] < size.viewSize[1]) {
        						   y = point[1]
        						} else {
        						   y = "50%"
        						}
        						return [x, y];
      						},
							// formatter: function(params) {
							// 	let a = "<span style='margin: 0 10px;'>:</span>"
							// 	let b =
							// 		`<span style='font-weight:700;margin-right:10px;'>${params.value}</span>`
							// 	return `${params.marker}${params.name}${a}${b}亩`
							// },
							formatter: "{b} : {c}亩",
							trigger: 'item'
						},
						series: [{
							type: 'pie',
							radius: '90%',
							data: this.pieData,
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							},
							labelLine: {
								show: false,
							},
							label: {
								normal: {
									show: false,
									position: 'inner',
									color: '#fff',
									formatter: function(d) {
										that.emphasis_echarts.push(d.percent)
										return d.percent + '%'
									},
									textStyle: {
										color: '#fff',
										fontSize: 14 // 提示文字大小
									}
								}
							},
							itemStyle: {
								normal: {
									color: function(colors) {
										return that.colorList[colors.dataIndex];
									}
								},
							}
						}],

					};
					that.emphasis_echarts.forEach((item,index) =>{
						// console.log(index,'-----------')
						if(index%2 == 0 || index != 0){
							that.emphasis_echarts.splice(index,1)
						}
					})
					charts.setOption(option, true);
					window.addEventListener("resize", () => {
						charts.resize();
					});
					charts.on("click", (data) => {
						this.cleanAllPolygon();
						this.toggleShowByType(data.dataIndex);
					});
				})
			},
			//单击乡村经营主体的标注事件
			showXcjyztInfo(item){
				//this.$refs.ref_pop_info.v_show_info = false;
				this.$refs.ref_pop_info.forEach(item2 => { 
					if(item.name != item2.name){
						item2.hideInfo(); 
					}else{
						if(item.intro){
							if(item2.isDisplay()){
								item2.hideInfo();
							}else{
								item2.showInfo();
							}
						}
					}
				});
			},
			// 乡村经营主体定位
			onCheckType2: function(type) {
				type == this.dotPopActive ? this.dotPopActive = -1 : this.dotPopActive = type
				let isShow=true
				console.log('type---',type);
				if(this.$refs.ref_pop_info){
					this.$refs.ref_pop_info.forEach(item2 => {item2.hideInfo();});
				}
				switch (type) {
					case 0:
						this.dotColor = '#1BCDBA'
						this.dotIcon = require('../assets/marker/cooperation.png');
						// this.dotData = this.entityData[0]
						let data =  this.entityData[0] || []
						let arr = []
						data.map((item)=>{
							arr.push({...item,isShow})
						})
						this.dotData = arr
						break;
					case 1:
						this.dotColor = '#FF943E'
						this.dotIcon = require('../assets/marker/enterprise.png');
						this.dotData = this.entityData[1]
						// let data2 =  this.entityData[1]
						// let arr2 = []
						// data2.map((item)=>{
						// 	arr2.push({...item,isShow})
						// })
						// this.dotData = arr2
						break;
					case 2:
						this.dotIcon = require('../assets/marker/agricultural.png');
						this.dotData = this.agriculturalEquipmentData
						this.dotColor = '#CFA972'
						break;
					case 3:
						this.dotIcon = require('../assets/marker/drone.png');
						this.dotData = this.droneData
						this.dotColor = '#36C5D7'
						break;
					case 4:
						this.dotIcon = require('../assets/marker/iotDevice.png');
						this.dotData = this.iotDeviceData;
						break;
					case 5:
						this.dotIcon = require('../assets/marker/iotDevice.png');
						this.dotData = this.v_device_data;
						break;	
				}
				//地图缩放到合适的比例
				var x_min = 180;
				var x_max = 0;
				var y_min = 90;
				var y_max = 0;
				if (this.dotData.length == 0) {
					return;
				}
				for (var item of this.dotData) {
					var x = item.geometry.center.x;
					var y = item.geometry.center.y;
					x_min = x_min > x ? x : x_min;
					x_max = x_max < x ? x : x_max;
					y_min = y_min > y ? y : y_min;
					y_max = y_max < y ? y : y_max;
				}
				var _envelope = getEnvelope(this.dotData);
				if (_envelope.length > 0) {
					viewer.camera.flyTo({
						destination: Cesium.Rectangle.fromDegrees(
							_envelope[0],
							_envelope[1],
							_envelope[2],
							_envelope[3]
						) //west, south, east, north)
					});
				}
			},
			// 初始化产业布局数据
			initIndustryData: function() {
				getIndustrialList().then(res => {
					let options = industryOptions
					options.xAxis.data = []
					options.series[0].data = []
					res.data.typeCount.forEach(item => {
						options.xAxis.data.push(item.typeName)
						options.series[0].data.push(item.count)
					});
					this.initCharts('industryCharts', options)
					// 表格数据处理
					res.data.industrialList.forEach(item => {
						switch (item.name) {
							case '种植业':
								this.industryTabData[0].td = item.list;
								break;
							case '林业':
								this.industryTabData[1].td = item.list;
								break;
							case '畜牧业':
								this.industryTabData[2].td = item.list;
								break;
							case '水产业':
								this.industryTabData[3].td = item.list;
								break;
						}
					})
					this.industryTabData.forEach(item => {
						let num = 10 - item.td.length
						let td = {
							cropName: '',
							areaValue: '',
							productionValue: ''
						}
						for (let i = 0; i < num; i++) {
							item.td.push(td)
						}
					})

				})
			},
			// 初始化图表
			initCharts: function(id, data) {
				let charts = echarts.init(document.getElementById(id));
				charts.setOption(data);
				window.addEventListener("resize", () => {
					charts.resize();
				});
				// charts.on('click', params => {
				// 	this.cleanAllPolygon();
				// 	this.requestNlmy(params.name, params.dataIndex);
				// })
				let that = this
				charts.getZr().on('click', function (params) {
                    let pointInPixel = [params.offsetX, params.offsetY];
                    if (charts.containPixel('grid', pointInPixel)) {
                        let pointInGrid = charts.convertFromPixel({
                          seriesIndex: 0
                        }, pointInPixel);
                        let xIndex = pointInGrid[0]; 			// 索引
                        let handleIndex = Number(xIndex);
                        let name = data.xAxis.data[handleIndex] 
						that.cleanAllPolygon();
                        that.requestNlmy(name,handleIndex);
                    }
                })
			},
			//单击是柱状图的名称，序号，0 1 2 3 农林牧渔
			requestNlmy(name, index) {
				console.log('index---',index)
				var _str_filter = "SSCID= '" + this.g_xqid + "' and lx='" + name + "' ";
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_chanye_1"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["id", "mc", "lx", "mj"],
					},
					"maxFeatures": 3000
				}
				http.requestIServerWithPage(_sqlParameter, 0, 3000, (result) => {
					console.log('--第一产业--', result.totalCount);
					g_dycy_list = [];
					var _config = {
						alpha: 0.6,
						polygonFillColor: 'rgb(20, 177, 222)'
					};
					for (var i = 0; i < result.features.length; i++) {
						var _feature = result.features[i];
						var _regions = getRegionFromPoints(_feature.geometry);
						var _xy_array = _regions.shift();
						var _item = {
							id: _feature.fieldValues[0],
							mc: _feature.fieldValues[1],
							lx: _feature.fieldValues[2],
							mj: parseInt(_feature.fieldValues[3]),
							holes: _regions,
							shape: _xy_array//getLonLatArray(_feature.geometry.points)
						};
						g_dycy_list.push(_item);
						addPolygonFunction('dycy' + index, i, '', _item.shape, null, _config, _item.holes);

					}
				});
			},
			// 获取乡村经营主体
			getEntity: function() {
				let that = this
				getEntity().then(res => {
					this.entityData = [
						[],
						[]
					]
					res.rows.forEach(item => {
						item.forEach(i => {
							if (i.type == '合作社') {
								that.entityData[0].push(i)
							} else if (i.type == '农业企业') {
								that.entityData[1].push(i)
							}
						})
					})
				})
			},
			// 搜索土地用途
			searchLand: function() {
				this.searchState = true
				console.log(this.searchText)
				let json = {
					filterStr: this.searchText
				}
				getSearchLand(json).then(res => {
					if (res.code != 200) {
						this.$emit('showMessage', res.msg)
					} else if (res.rows.length < 1) {
						this.$emit('showMessage', '暂未收录该用户相关信息！')
					} else {
						this.landSearchList = true
						this.landSearchData = res.rows
					}
				})
			},
			// 智慧农业板块图片轮播效果
			animationImg: function(index) {
				this.imgCurIndex = index
				this.translateX = 'translateX(-' + index * 100 / 3 + '%)'
			},
			// 获取农业设备数据
			getEquipment: function() {
				// 农机设备
				getEquipment().then(res => {
					console.log(res, '农机设备')
					this.agriculturalEquipmentData = res.rows
				})
				// 无人机设备
				getDrone().then(res => {
					console.log(res, '无人机设备')
					this.droneData = res.rows
				})
				// 物联网设备
				getIotDevice().then(res => {
					console.log(res, '物联网设备')
					this.iotDeviceData = res.rows
				})
				//查兴趣点图层
				console.log('农产品快检设备');
				this.requestDeviceList();
			},

			requestDeviceList: function() {
				var _str_filter = "SSCID= '" + this.g_xqid + "' and lx='农产品快检设备'";
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t03_xqd"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["id", "mc", "lx"],
						"orderby": 'id'
					},
					"maxFeatures": 100
				}
				http.requestIServerWithPage(_sqlParameter, 0, 100, (result) => {
					console.log('--设备回调--', result);
					if (result.features.length > 0) {
						this.v_device_data = [];
						for (var i = 0; i < result.features.length; i++) {
							var _feature = result.features[i];
							this.v_device_data.push({
								id: _feature.fieldValues[0],
								name: _feature.fieldValues[1],
								isShow: true,
								intro: _feature.fieldValues[1],
								geometry: {center: _feature.geometry.center,
									pointArr: [_feature.geometry.center.x, _feature.geometry.center.y]}
							});
						}
					}
				});
			},

			// 点击3D饼图
			changeEcharts: function(e) {
				this.cleanAllPolygon();
				this.toggleShowByType(e);
			},
			// 初始化地块图层
			initLandMap() {
				getLand().then(res => {
					res.rows.shift()
					// console.log(res.rows)
					// for (let item of res.rows) {
					//     console.log(item)
					//     switch (item.type) {      //农farm 林forestry 牧animal 渔fishery
					//         case 'farm':
					//             addPolygonFunction('farm',item.id, item.name, item.geometry.pointArr, null, { polygonFillColor: 'rgba(125, 191, 80,0.9)', alpha: 0.8 });
					//             break;
					//         case 'forestry':
					//             addPolygonFunction('forestry',item.id, item.name, item.geometry.pointArr, null, { polygonFillColor: 'rgba(101, 190, 196,0.9)', alpha: 0.8 });
					//             break;
					//         case 'animal':
					//             addPolygonFunction('animal',item.id, item.name, item.geometry.pointArr, null, { polygonFillColor: 'rgba(101, 190, 196,0.9)', alpha: 0.8 });
					//             break;
					//         case 'fishery':
					//             addPolygonFunction('fishery',item.id, item.name, item.geometry.pointArr, null, { polygonFillColor: 'rgba(0, 179, 255,0.9)', alpha: 0.8 });
					//             break;
					//     }
					// }
					// let shape = getLonLatArray(res.rows[0].geometry.points)
					// console.log(res, '初始化地块图层', shape)
					// addPolygonFunction('qw', '123', '123', res.rows[0].geometry.pointArr, null, { polygonFillColor: 'rgba(125, 191, 80,0.9)', alpha: 0.8 });
				})
			},

			//请求土地用途
			requestTdyt() {
				var _str_filter = "SSCID= '" + this.g_xqid + "' ";
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_tdly"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["id", "mc", "dl", "mj"],
					},
					"maxFeatures": 2000
				}
				http.requestIServerWithPage(_sqlParameter, 0, 1000, (result) => {
					console.log('--接收土地用途--', result.totalCount);
					g_tdly_list[0].list = [];
					g_tdly_list[1].list = [];
					g_tdly_list[2].list = [];
					g_tdly_list[0].area = 0;
					g_tdly_list[1].area = 0;
					g_tdly_list[2].area = 0;
					if (result.features.length > 0) {
						var _config = {
							alpha: 0.6,
							classificationType: 'S3M_TILE'
						};
						for (var i = 0; i < result.features.length; i++) {
							var _feature = result.features[i];
							var _regions = getRegionFromPoints(_feature.geometry);
        					var _xy_array = _regions.shift();
							var _item = {
								id: _feature.fieldValues[0],
								mc: _feature.fieldValues[1],
								lx: _feature.fieldValues[2],
								mj: parseInt(_feature.fieldValues[3]),
								holes: _regions,
								shape: _xy_array   //getLonLatArray(_feature.geometry.points)
							};

							if (_item.lx == '农用地') {
								g_tdly_list[0].list.push(_item);
								g_tdly_list[0].area += (_item.mj);
							} else if (_item.lx == '建设用地') {
								g_tdly_list[1].list.push(_item);
								g_tdly_list[1].area += _item.mj;
							} else {
								g_tdly_list[2].list.push(_item);
								g_tdly_list[2].area += (_item.mj);
							}
						}
						console.log('--土地用途面积--', g_tdly_list[0].area, g_tdly_list[1].area, g_tdly_list[2].area);
						if (this.menu_active == '1') {
							this.showAllTdlyPolygon();
						}
					}
				});
			},
			toggleShowByType(type) {
				console.log(type, g_tdly_list[0].isShow, g_tdly_list[1].isShow, g_tdly_list[2].isShow);
				if (type == 0) {

					if (g_tdly_list[0].isShow) {
						cleanMarkerListByType('tdyt0');
						g_tdly_list[0].isShow = false;
					} else {
						var _config = {
							polygonFillColor: 'rgb(127, 194, 81)',
							alpha: 0.6,
							classificationType: 'S3M_TILE'
						};
						for (var _item of g_tdly_list[0].list) {
							addPolygonFunction(g_tdly_list[0].type, _item.id, '', _item.shape, null, _config);
						}
						g_tdly_list[0].isShow = true;
					}

				} else if (type == 1) {

					if (g_tdly_list[1].isShow) {
						cleanMarkerListByType('tdyt1');
						g_tdly_list[1].isShow = false;
					} else {
						var _config = {
							polygonFillColor: 'rgb(49, 144, 210)',
							alpha: 0.6,
							classificationType: 'S3M_TILE'
						};
						for (var _item of g_tdly_list[1].list) {
							addPolygonFunction(g_tdly_list[1].type, _item.id, '', _item.shape, null, _config);
						}

						g_tdly_list[1].isShow = true;
					}

				} else {

					if (g_tdly_list[2].isShow) {
						cleanMarkerListByType('tdyt2');
						g_tdly_list[2].isShow = false;
					} else {
						var _config = {
							polygonFillColor: 'rgb(227, 92, 122)',
							alpha: 0.6,
							classificationType: 'S3M_TILE'
						};
						for (var _item of g_tdly_list[2].list) {
							addPolygonFunction(g_tdly_list[2].type, _item.id, '', _item.shape, null, _config);
						}
						g_tdly_list[2].isShow = true;
					}

				}
			},
			handlerAction(movement) {
				var pick = viewer.scene.pick(movement.position);
				if (Cesium.defined(pick)) {
					var _entity = pick.id;
					console.log('选取 entity 对象', _entity, movement.position);
					if (_entity.ptype == 'tdyt0' || _entity.ptype == 'dycy0') {
						this.handlerTdlyClickEvent(_entity);
					} else if (_entity.ptype == 'tdlydkpoint') { //处理单击地块上的marker事件
						this.handlerDkMarkerClickEvent(_entity);
					} else if (_entity.ptype == 'tdlydk') { //处理在地块面上事件
						this.handlerDkPolygonClickEvent(_entity);
					}
				}
			},

			//处理单击土地利用的面
			handlerTdlyClickEvent(_entity) {
				viewer.flyTo(_entity, {
					offset: {
						heading: 0.0,
						pitch: -1.5,
						range: 100.0
					}
				});
				if (_entity.polygon) { //如果选中的是面        
					// _entity.polygon.outlineColor = Cesium.Color.RED;
					// _entity.polygon.outline = true;
					// _entity.polygon.outlineWidth = 2; 

					var _dke_zb_list = _entity.polygon.hierarchy.getValue().positions
					// console.log('笛卡尔坐标--', _dke_zb_list);
					var _jw_zb_list = [];
					for (var _dke_zb of _dke_zb_list) {
						var _cartesian3 = new Cesium.Cartesian3(_dke_zb.x, _dke_zb.y, _dke_zb.z);
						var _cartographic = viewer.scene.globe.ellipsoid.cartesianToCartographic(_cartesian3);
						var lon = Cesium.Math.toDegrees(_cartographic.longitude);
						var lat = Cesium.Math.toDegrees(_cartographic.latitude);
						_jw_zb_list.push({
							x: lon,
							y: lat
						});
					}
					// console.log('经纬度坐标--', _jw_zb_list);
					this.requestDkListByPolygon(_jw_zb_list);
					//focusEntity(pick.id);
				}
			},

			//处理单击地块上的marker事件
			handlerDkMarkerClickEvent(_entity) {
				if (g_dk_entity) {
					g_dk_entity.billboard.image = g_dk_entity.image2;
				}
				_entity.billboard.image = require('../assets/marker/dk.png');
				g_dk_entity = _entity;
				this.landWinPop = true; //显示详情面板
				this.page_temp_opcity = 0;
				this.tabTitleCur = 0
				var _dk_data = g_dk_list[_entity.data.id];
				this.v_hu_info.hid = _dk_data.hid;
				this.v_hu_info.xm = _dk_data.xm;
				this.v_dk_info.mj = _dk_data.mj;
				this.v_dk_info.dklx = _dk_data.dklx;
				// this.v_dk_info.nzwlist = _dk_data.nzwlist;
				this.landTabData[1].td = _dk_data.nzwlist;
				//console.log('month', _dk_data.nzwlist);
				this.requestHuInfo(_dk_data.hid);
			},

			//处理在地块面上事件
			handlerDkPolygonClickEvent(_entity) {
				viewer.entities.removeById('tdlydk-center');
				var _point = [_entity.data.cx, _entity.data.cy];
				addPoint('tdlydk', 'center', '', _point);
				this.landWinPop = true; //显示详情面板
				this.page_temp_opcity = 0;
				this.tabTitleCur = 0
				var _dk_data = g_dk_list[_entity.data.id];
				this.v_hu_info.hid = _dk_data.hid;
				this.v_hu_info.xm = _dk_data.xm;
				this.v_dk_info.mj = _dk_data.mj;
				this.v_dk_info.dklx = _dk_data.dklx;
				// this.v_dk_info.nzwlist = _dk_data.nzwlist;
				this.landTabData[1].td = _dk_data.nzwlist;
				//console.log('month', _dk_data.nzwlist);
				this.requestHuInfo(_dk_data.hid);
			},

			closeLandWinPop() {
				this.landWinPop = false;
				this.page_temp_opcity = 1;
				this.tabTitleCur = 0
				if (g_dk_entity) {
					g_dk_entity.billboard.image = g_dk_entity.image2;
				}
				viewer.entities.removeById('tdlydk-center');
				console.log('landWinPop----',this.landWinPop)
			},

			requestDkListByPolygon(shape) {
				//console.log('--shape-');
				var _sqlParameter = {
					"getFeatureMode": "SPATIAL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_dk"],
					"spatialQueryMode": "CONTAIN", //INTERSECT
					"queryParameter": {
						"fields": ["smid", "QSRMC", "SFZH", "DKMC", "DKLX", "HCMJ", "M1", "M2", "M3", "M4", "M5", "M6",
							"M7", "M8", "M9", "M10", "M11", "M12", "X", "Y"
						]
					},
					"geometry": {
						"type": "REGION",
						"points": shape
					}
				};
				http.requestIServerWithPage(_sqlParameter, 0, 1000, (result) => {
					console.log('--接收地块--', result.totalCount);

					this.cleanAllPolygon();

					var _config = {
						alpha: 0.6,
						classificationType: 'S3M_TILE',
						polygonFillColor: 'rgb(127, 194, 81)', //'rgb(227, 92, 122)',
						outlineColor: Cesium.Color.YELLOW,
						outlineWidth: 2,
						distanceDisplayCondition: 500,
						z: this.$parent.getZ()
					};
					g_dk_list = [];
					for (var [_index, _feature] of result.features.entries()) {
						var _regions = getRegionFromPoints(_feature.geometry);
        				var _xy_array = _regions.shift();
						var _nzwmc_list = []; //农作物名称
						for (var i = 0; i < 12; i++) {
							_nzwmc_list.push({
								name: (i + 1) + '月',
								m1: _feature.fieldValues[i + 6]
							});
						}
						var _item = {
							id: _index, //_feature.fieldValues[0],
							xm: _feature.fieldValues[1],
							hid: _feature.fieldValues[2],
							dklx: _feature.fieldValues[4],
							mj: _feature.fieldValues[5],
							nzwlist: _nzwmc_list,
							holes: _regions,
							shape: _xy_array//_feature.geometry.points
						};
						//var _shape = getLonLatArray(_item.shape);
						_config.cx = _feature.geometry.center.x;
						_config.cy = _feature.geometry.center.y;
						addPolygonFunction('tdlydk', _item.id, '', _item.shape, null, _config, _item.holes);
						// var _point = [_feature.geometry.center.x, _feature.geometry.center.y];
						// addPoint('tdlydkpoint', _item.id, _item.xm, _point, null, _config);
						g_dk_list.push(_item);
					}

				});
			},

			requestDkBySmid(smid) {
				console.log('--dk smid-', smid);
				this.landWinPop = true;
				this.page_temp_opcity = 0;
				this.landSearchList = false;

				var _str_filter = "SSCID='" + this.g_xqid + "' and smid=" + smid;
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_dk"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["smid", "QSRMC", "SFZH", "DKMC", "DKLX", "HCMJ", "M1", "M2", "M3", "M4", "M5", "M6",
							"M7", "M8", "M9", "M10", "M11", "M12", "X", "Y"
						]
					}
				};
				http.requestIServer(_sqlParameter, (result) => {
					console.log('--根据smid查询地块--', result.totalCount);
					this.cleanAllPolygon();

					var _config = {
						alpha: 0.6,
						classificationType: 'S3M_TILE',
						polygonFillColor: 'rgb(227, 92, 122)',
						outlineColor: Cesium.Color.YELLOW,
						outlineWidth: 2,
						distanceDisplayCondition: 500,width: 25, height: 30,
						z: this.$parent.getZ(),
						image: require('../assets/marker/dk.png')
					};
					g_dk_list = [];
					for (var [_index, _feature] of result.features.entries()) {
						var _regions = getRegionFromPoints(_feature.geometry);
        				var _xy_array = _regions.shift();
						var _nzwmc_list = []; //农作物名称
						for (var i = 0; i < 12; i++) {
							_nzwmc_list.push({
								name: (i + 1) + '月',
								m1: _feature.fieldValues[i + 6]
							});
						}
						var _item = {
							id: _index, //_feature.fieldValues[0],
							xm: _feature.fieldValues[1],
							hid: _feature.fieldValues[2],
							dklx: _feature.fieldValues[4],
							mj: _feature.fieldValues[5],
							nzwlist: _nzwmc_list,
							holes: _regions,
							shape: _xy_array
							//shape: _feature.geometry.points
						};
						this.v_hu_info.hid = _item.hid;
						this.v_hu_info.xm = _item.xm;
						this.v_dk_info.mj = _item.mj;
						this.v_dk_info.dklx = _item.dklx;

						//var _shape = getLonLatArray(_item.shape);
						addPolygonFunction('tdlydk', _item.id, '', _item.shape, null, _config, _item.holes);
						var _point = [_feature.geometry.center.x, _feature.geometry.center.y];
						addPoint('tdlydkpoint', _item.id, '', _point, null, _config);
						g_dk_list.push(_item);
						this.requestHuInfo(_item.hid);

						var _entity = getMakerById('tdlydkpoint-' + _item.id);
						viewer.flyTo(_entity, {
							offset: {
								heading: 0.0,
								pitch: -1.5,
								range: 800.0
							}
						});
					}

				});
			},

			requestHuInfo(hid) {
				if (!hid) {
					return;
				}
				var _str_filter = "sfzh = '" + hid + "' ";
				var _sqlParameter = {
					"getFeatureMode": "SQL",
					"datasetNames": ["p" + this.g_dsz_id + ":t02_hu"],
					"queryParameter": {
						"attributeFilter": _str_filter,
						"fields": ["id", "hzxm", "sfzh", "dz", "lxdh", "rs", "hjzmj"]
					},
					"maxFeatures": 10
				}
				http.requestIServer(_sqlParameter, (result) => {
					console.log('--接收户信息--', result.totalCount);
					if (result.totalCount > 0) {
						var _feature = result.features[0];
						this.v_hu_info.csrq = getCsrqFromSfzh(_feature.fieldValues[2]);
						this.v_hu_info.dz = _feature.fieldValues[3];
						this.v_hu_info.lxdh = _feature.fieldValues[4];
						this.v_hu_info.rks = _feature.fieldValues[5];
					} else {
						this.v_hu_info.csrq = '';
						this.v_hu_info.dz = '';
						this.v_hu_info.lxdh = '';
						this.v_hu_info.rks = '';
					}
				});
			},
			//
			cleanAllPolygon() {
				g_tdly_list[0].isShow = false;
				g_tdly_list[1].isShow = false;
				g_tdly_list[2].isShow = false;
				cleanMarkerListByType('tdyt0');
				cleanMarkerListByType('tdyt1');
				cleanMarkerListByType('tdyt2');
				cleanMarkerListByType('tdlydk');
				cleanMarkerListByType('tdlydkpoint');
				cleanMarkerListByType('cooperation');
				cleanMarkerListByType('enterprise');
				cleanMarkerListByType('agricultural');
				cleanMarkerListByType('droneData');
				cleanMarkerListByType('iotDeviceData');
				cleanMarkerListByType('deviceData');
				cleanMarkerListByType('dycy0');
				cleanMarkerListByType('dycy1');
				cleanMarkerListByType('dycy2');
				cleanMarkerListByType('dycy3');
				this.dotPopActive = -1;
				viewer.entities.removeById('tdlydkcenter');
			}

		},

		destroyed() {
			this.cleanAllPolygon();
			g_handler = g_handler && g_handler.destroy();
		}
	}
</script>

<style lang="scss" scoped>
.cls-pane-content{
	font-size: 14px;
    color: #fff;
    line-height: 30px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 250px;
    margin-top: 5px;
	padding-right: 3px;
}
	.search {
		width: auto;
		height: .37rem;
		border-radius: .17rem;
		border: solid 1px #79b7f7;
		padding-left: .16rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: #fff;
		box-sizing: border-box;
		z-index: 900;
		margin: 0.08rem;

		background: rgba(4, 43, 102, 0.6);
		box-shadow: inset 0px 0px 0.08rem 0px #0856CC;
		border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
		opacity: 1;
		border: 0.01rem solid #0856CC;

		input {
			flex: 1;
			width: 40%;
			padding-left: .04rem;
			margin-left: .04rem;
			border: none;
			outline: none;
			background-color: transparent;
			// border-left: .02rem solid #ddd;
			color: #fff;
			font-size: .14rem;
		}

		input::placeholder {
			font-size: .14rem;
			color: #ddd;
		}

		span {
			font-size: .14rem;
			width: 80%;
		}

		i {
			font-size: .17rem;
			cursor: pointer;
		}

		.search-btn {
			background: #09C8E5;
			border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;

			margin-right: .01rem;
			border-radius: .15rem;
			width: .8rem;
			height: .3rem;
			box-sizing: border-box;
			font-size: .12rem;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			padding: 0;
			margin-right: 0.04rem;
			cursor: pointer;
		}

		.searchListBox {
			width: 100%;
			position: absolute;
			z-index: 999;
			left: 0;
			top: 0.8rem;
			display: flex;
			justify-content: center;

			.searchList {
				width: 90%;
				height: auto;
				border-radius: 0px 0px .08rem .08rem;
				border: solid .01rem #79b7f7;
				background-color: #00000092;
				box-shadow: 0px 0px .06rem 0px #79b7f7 inset;
				padding: .1rem;
				box-sizing: border-box;

				li {
					list-style-type: none;
					width: 100%;
					height: .24rem;
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-between;

					span:first-child {
						color: #79b7f7;
						margin-right: .1rem;
						cursor: pointer;
						display: inline-block;
						width: .5rem;
						text-align: justify;
						text-align-last: justify;
					}

					span:last-child {
						display: flex;
						flex: 1;
						flex-wrap: nowrap;
						justify-content: flex-end;
					}
				}
			}
		}
	}

	#industryCharts {
		width: 3.2rem;
		height: 1.58rem;
	}

	.landWinPop {
		top: .8rem;
	}

	.industry,
	.landWinPop {
		z-index: 999;

		.tab-list {
			width: 100%;
			display: flex;
			justify-content: space-between;

			span {
				width: .8rem;
				height: .24rem;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: .14rem;
				background-color: #003cb2;
				border-radius: .05rem;
			}

			div {
				width: .8rem;
				height: .24rem;
				cursor: pointer;
			}

			.tab-title-cur {
				// background-image: url(../assets/img/farming/tab-title-cur.png);
				background-color: #003cb2;
				border-radius: 5px;
				border: solid 1px #79b7f7;

				span {
					background-color: transparent;
				}
			}
		}

		table {
			width: 100%;
			border-collapse: collapse;
			font-size: .14rem;
			margin-top: .1rem;
			color: #fff;
			height: .7rem;
			border-color: transparent;

			th {
				height: 0.24rem;
				background: rgba(6, 65, 153, 0.6);
				color: rgba(255, 255, 255, 0.6);
				font-weight: 500;
				font-size: 0.14rem;
				line-height: 0.24rem;
			}

			tr {
				height: .24rem;
				font-size: .14rem;
				margin-bottom: 0.08rem;
				box-sizing: border-box;
				color: #dfdfdf;

				td {
					text-align: center;
					font-size: .14rem;
				}
			}
		}

		table>tr:first-child {
			height: .38rem;
		}
	}

	.landWinPop {
		.tab-list {
			div {
				width: 1.1rem;
			}

			span {
				width: 1.1rem;
			}

			.tab-title-cur {

				span {
					background-color: #79b7f7;
				}
			}
		}
	}

	// 乡村经营主体
	.manage {
		.item-box {
			width: 100%;
			height: 2rem;
			border: .01rem solid #ddd;
			border-radius: .1rem;
			position: relative;
			overflow: hidden;
			cursor: pointer;
			margin-top: .12rem;

			img {
				object-fit: cover;
				// width: 100%;
				height: 100%;
			}

			span {
				display: flex;
				width: 100%;
				height: .3rem;
				font-size: .16rem;
				background-color: rgba(0, 0, 0, 0.6);
				position: absolute;
				bottom: 0;
				left: 0;
				justify-content: center;
				align-items: center;
				color: #fff;
			}
		}

		.item-box:hover,
		.item-box:hover span,
		.item-box_cur {
			border-color: #79b7f7;
			color: #79b7f7;
		}
	}

	.manage>span {
		display: inline-block;
		width: 100%;
		text-align: center;
	}

	.equipment {
		span {
			color: #79b7f7;
		}

		.item-box {
			display: flex;
			width: 100%;
			justify-content: space-between;
			box-sizing: border-box;

			.item-in {
				height: .6rem;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				cursor: pointer;
			}

			.item-in:first-child {
				width: 1.8rem;
				background-image: url(../assets/img/farming/b2.png);
			}

			.item-in:last-child {
				width: 1.6rem;
				background-image: url(../assets/img/farming/b1.png);
			}
		}

		.item-out {
			width: 100%;
			height: .6rem;
			background-image: url(../assets/img/farming/b3.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin-top: .14rem;
			cursor: pointer;
		}

		.text {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: .14rem;
			padding-left: .6rem;
			box-sizing: border-box;
		}
	}

	#landCharts {
		height: 2rem;
	}

	// 智慧农业版块
	.wisdom {
		width: 100%;

		.title {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			span,
			i {
				font-size: .14rem;
			}

			.left {
				display: flex;
				align-items: center;
			}

			.left>span {
				cursor: pointer;
			}

			.left>span:nth-child(2) {
				display: inline-block;
				margin: 0 .2rem;
				// border-left: .01rem solid #ddd;
				height: .12rem;
			}

			.wisdoeCur {
				color: #79b7f7;
			}
		}

		.video-box {
			width: 3.3rem;
			height: 2rem;
			margin-top: .1rem;
			overflow: hidden;

			.img-box {
				width: 300%;
				display: flex;
				justify-content: space-between;
				flex-wrap: nowrap;

				img {
					width: 3.3rem;
				}

				transition: 1s linear;
			}
		}

		.cur-box {
			width: 100%;
			height: .12rem;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				display: inline-block;
				width: .08rem;
				height: .08rem;
				border-radius: 50%;
				background-color: rgba(221, 221, 221, 0.664);
			}

			span:nth-child(2) {
				margin: 0 .1rem;
			}

			.img-cur {
				width: .12rem;
				height: .12rem;
				background-color: #fff;
			}
		}
	}

	.pie-box {
		display: flex;
		justify-content: space-between;
		flex-direction: row;

		.pie-hint {
			width: 50%;
			font-size: .12rem;
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;

			div {
				display: flex;
				align-items: center;
				width: 66%;
				color: rgba(255, 255, 255, 0.6);
				font-size: 0.12rem;
				justify-content: space-between;
				margin: 0.04rem 0 0.04rem 0.32rem;
				
				span {
					display: flex;
					width: .16rem;
					height: .09rem;

					margin-right: .1rem;
				}
			}

		}

		.pie-chart {
			width: 1.6rem;
			height: 1.6rem;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			border: 0.02rem solid rgba(0, 255, 255, 0.3);
			border-radius: 50%;
			position: relative;
			.center{
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 0.28rem;
				width: 0.4rem;
				height: 0.4rem;
				background-color: #142D50;
				position: absolute;
				z-index: 99;
				border-radius: 50%;
			}
			#pie-chart {
				width: 100%;
				height: 1.6rem;
			}

			.border {
				position: absolute;
				width: 10%;
				height: 10%;
				border: .08rem solid #fff;
				border-radius: 50%;
				background-color: rgba(49, 143, 210, 0.342);
			}
		}

		.hint-box {
			width: 50%;
			height: 1.6rem;
			font-size: .14rem;
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: flex-start;
			padding: .2rem 0;
			box-sizing: border-box;

			span {
				display: inline-block;
			}

			span:first-child {
				width: .1rem;
				height: .1rem;
				border-radius: 50%;
			}

			span:nth-child(2) {
				width: .6rem;
				margin: 0 .1rem;
			}

			span:nth-child(3) {
				color: #ffb956;
			}
		}
	}

	.landWinPop {

		// background: linear-gradient(180deg, rgba(2, 28, 51, 0.8) 0%,
		// 		rgba(0, 255, 255, 0.6) 51%,
		// 		rgba(2, 28, 51, 0.8) 100%);
		
		border: 0.01rem solid rgba(0, 255, 255, 1);
		
		border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
		padding: .1rem .2rem .24rem;
		width: 4.36rem;
		height: 4.46rem;
		position: absolute;
		z-index: 999;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		font-size: 0.12rem;
		&::after{
			content:'';
			height: 100%;
			width: 100%;
			background: linear-gradient(180deg, rgba(2, 28, 51, 0.7) 0%, rgba(4,56,102,0.7) 51%, rgba(2,28,51,0.7) 100%);
			filter: blur(3px);
			border-radius: 0.16rem 0.16rem 0.16rem 0.16rem;
		padding: .1rem .2rem .24rem;
		width: 4.36rem;
		height: 4.46rem;
		position: absolute;
		top:0;
		left: 0;
		z-index: -1;
		}
		.title {
			display: flex;
			justify-content: space-between;
			font-size: 0.16rem;
			line-height: 0.30rem;
			color: rgba(255, 255, 255, 0.9);
			border-bottom: 0.01rem solid;
			border-image: linear-gradient(to right, rgba(0, 255, 255, 1), rgba(24, 142, 245, 0.1)) 1 1;

			i {
				font-size: 0.17rem;
				line-height: 0.3rem;
				cursor: pointer;
			}
		}

		.row-1 .el-col {
			display: flex;
			align-items: center;
			margin: 0.16rem 0;

			img {
				width: 0.24rem;
				height: 0.24rem;
				margin-right: 0.04rem;
			}

			span {
				color: rgba(225, 225, 225, 0.6);
			}

			p {
				color: #fff;
			}
		}

		.row-2 {
			display: flex;
    		flex-wrap: wrap;
    		justify-content: space-between;
			li {
				list-style-type: none;
				color: #fff;
				&>img{
					margin-right: 0.05rem;
				}
				span:first-child {
					color: rgba(255, 255, 255, 0.6);
				}

				line-height: 0.18rem;
			}
			.flex_c{
				    display: flex;
    				flex-wrap: wrap;
    				justify-content: flex-start;
    				width: 50%;
					margin-bottom: 0.05rem;
					&>div>p:first-child{
						color: rgba(255,255,255,0.6);
					}
			}
		}

		.row-3 {
			display: flex;
			width: 100%;
			justify-content: space-between;

			span {
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 1.24rem;
				height: 0.24rem;
				// background: #042B66;
				border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
				font-size: 0.14rem;
				color: #fff;
				margin-top: 0.16rem;
				cursor: pointer;
				border: 1px solid rgba(0,255,255,0.3);
			}

			.cur_pop_list {
				border: 0.01rem solid #00FFFF;
			}
		}

		.row-4 {
			text-align: center;
			margin-top: 0.16rem;
			background-color: rgba(4, 43, 102, 0.75);
			height: 0.24rem;
			display: flex;
			align-items: center;
			justify-content: space-around;
			font-size: 0.14rem;
			color: rgba(255, 255, 255, 0.6);
		}

		.row-5 {
			margin: 0.1rem 0;
			text-align: center;
			font-size: 0.14rem;
			color: #fff;
			display: flex;
			justify-content: space-around;
		}
	}

	.img-item {
		display: flex;
		align-items: center;
		width: 100%;
		background-color: rgba(225, 225, 225, 0.3);
		height: .68rem;
		border-radius: .04rem;
		margin-top: .15rem;
		box-sizing: border-box;
		overflow: hidden;
		padding-left: .24rem;

		img {
			margin-right: .16rem;
			width: .38rem;
			height: .38rem;
			border-radius: 50%;
		}

		div {
			font-size: .14rem;

			span {
				font-size: .16rem;
				margin: 0 .16rem;
				color: #fff000;
			}
		}
	}

	.img-box {
		display: flex;
		justify-content: space-between;
		cursor: pointer;

		.img-item {
			width: 48%;

			div {
				display: flex;
				flex-direction: column;

				div {
					display: flex;
					flex-direction: row;

					span {
						margin-left: 0;
					}
				}
			}
		}
	}

	.content_img_item {
		width: 48%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		cursor: pointer;
		font-size: 0.12rem;

		img {
			width: 1.24rem;
			height: 1.2rem;
		}

		span {
			font-size: .24rem;
			color: #fff;
			line-height: .3rem;
		}
	}

	.line_between {
		margin: .2rem 0;
		display: flex;
		width: 100%;
		align-items: center;
	}

	.info_title {
		display: flex;
		align-items: center;
		cite{
			font-style: normal;
			color: rgba(255,255,255,0.6);
			margin-left: 0.03rem;
		}
		.info_dot {
			width: 0.12rem;
			height: 0.12rem;
			margin: 0.1rem 0;
			border: 0.01rem solid #069999;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;

			span {
				display: block;
				width: 0.06rem;
				height: 0.06rem;
				box-shadow: 0 0 0.03rem 0.01rem #18F5F5;
				background-color: #18F5F5;
			}
		}
	}
	.content_img_item:hover{
		cursor: pointer;
		background-color: #09fafa20;
	}
	// 农业设备
	.content_img_item_list {
		cursor: pointer;
		&:hover{
			background-color: #09fafa20;
		}
		padding: 0 0.16rem;
		width: 100%;
		height: 0.56rem;
		box-sizing: border-box;
		border: 0.01rem solid;
		border-image: linear-gradient(273deg, rgba(51, 255, 238, 0), rgba(51, 255, 238, 0.4), rgba(51, 255, 238, 0)) 1 1;
		margin-bottom: 0.08rem;
		display: flex;
		align-items: center;
		cursor: pointer;

		img {
			width: 0.48rem;
			height: 0.48rem;
		}

		p {
			margin-left: 0.24rem;
			font-size: 0.12rem;
			line-height: 0.12rem;
			width: 0.92rem;
			height: 0.06rem;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			white-space: nowrap;
			background: linear-gradient(180deg, rgba(9, 98, 229, 0) 0%, #18F5F5 100%);
		}

		div {
			margin-left: 0.48rem;
			display: flex;
			flex-direction: column;

			span:first-child {
				font-size: 0.12rem;
				color: rgba(225, 242, 245, 0.8);
			}

			span:last-child {
				font-size: 0.2rem;
				color: #fff;
			}
		}
	}
	.el-icon-circle-close{
		font-size: 0.2rem !important;
		&:hover{
			transform: scale(1.2);
		}
	}
</style>
