import axios from "../axios/index"

// 乡村经营主体
export const getEntity = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/production/entity',data)
}
// 农业设备
export const getEquipment = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/production/equipment',data)
}
// 无人机设备
export const getDrone = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/production/drone',data)
}
// 物联网设备
export const getIotDevice = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/production/iotDevice',data)
}
// 产业布局列表
export const getIndustrialList = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/production/industrialList',data)
}
// 地块图层
export const getLand = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/production/land',data)
}
// 土地用途列表
export const getLandList = () => {
    let data = {
        tenantId:localStorage.getItem('tenantId')
    }
    return axios.get('/onemap/portal/production/landList',data)
}
// 地块查询
export const getSearchLand = (data) => {
    data.tenantId = localStorage.getItem('tenantId')
    return axios.get('/onemap/portal/production/searchLand',data)
}